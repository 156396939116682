@use 'src/styles/variables/colors';

.item {
  background-color: white;
  padding: 12px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  border: 0.5px solid #e9eaf3;
  margin-bottom: 12px;

  .chip {
    background: colors.$gray;
    border-radius: 23px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 9px;
    padding: 4px 8px;
  }

  .review {
    color: #009933;
  }

  .location {
    letter-spacing: 0.25px;
    font-size: 14px;
    color: #4d4d4d;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
  }

  .assignee {
    letter-spacing: 0.25px;
    font-size: 14px;
    .userIcon {
      width: 16px;
      position: relative;
      top: 3px;
    }
    .assigneeLabel {
      text-decoration: underline;
      color: colors.$primary;
    }
  }

  .reportStatusDropdown {
    position: absolute;
    display: none;
    left: 0;
    font-size: 14px;
    top: calc(100% + 5px);
    background-color: white;
    z-index: 9;
    color: black;
    width: 110px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.04))
      drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));

    & > span {
      padding: 8px 12px 8px 8px;
    }
    &.activeReportStatusDropdown {
      display: block;
    }
  }

  .reportStatusNS {
    color: #b3b3b3;
    border-radius: 15px;
    cursor: pointer;
    border: 1px dotted #b3b3b3;
    display: inline-block;
  }

  .reportStatusIP {
    cursor: pointer;
    border: 1px dotted transparent;
    display: inline-block;
  }

  .reportStatusDrafted {
    position: relative;
    color: #009933;
    cursor: pointer;
    margin-left: 12px;
    border: 1px dotted transparent;
    display: inline-block;
    &:before {
      content: '\2713';
      position: absolute;
      left: -5px;
      top: 2px;
    }
    &.dropdownReportStatusDrafted {
      &:before {
        content: '\2713';
        position: absolute;
        left: -5px;
        top: 5px;
      }
    }
  }

  .warningContent {
    position: relative;
    .warningIcon {
      min-width: 20px;
      cursor: pointer;
    }

    .warningLabel {
      display: none;
      left: 20px;
      position: absolute;
      white-space: nowrap;
      z-index: 9999;
      color: #cc0000;
      background-color: white;
      padding: 5px;
      border-radius: 2px;
      top: -3px;
    }
    &:hover .warningLabel {
      display: block;
    }

    &.submittedWaitingContent {
      .warningLabel {
        // right: 30px;
        left: -157px;
      }
    }
  }

  .editIcon {
    width: 24px;
    cursor: pointer;
  }

  .locationIcon {
    min-width: 16px;
    position: relative;
    top: -4px;
  }

  .warningLocationLabel {
    color: #cc0000;
  }

  .warningLocationIcon {
    path {
      fill: #cc0000;
    }
  }
}

.icon {
  height: 18px;
  width: 18px;
  position: relative;
  top: -1px;
}

.errorMessage {
  color: #cc0000;
}

.btnWrapper {
  border-top: 1px solid colors.$border-color;
  margin-top: 12px;
}
