@use 'src/styles/variables/colors';

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  text-align: right;
  padding: 12px 0;
  svg {
    width: 20px;
    cursor: pointer;
    height: 20px;
  }
  .arrowLeft {
    transform: rotate(180deg);
  }
  .paginationArrowContainer {
    position: relative;
    top: 3px;
  }

  .viewToggleIcon {
    border: 1px solid colors.$border-color;
    padding: 4px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
    top: 1px;
  }

  .activeViewToggleIcon {
    background: #e9eaf3;
    path {
      fill: colors.$primary;
    }
  }
}
