@use 'src/styles/variables/colors';

.card {
  max-width: 324px;
  width: 324px;
  margin: 10px 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
}

.media {
  height: 158px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.content {
  padding: 12px 14px;
}

.checkboxRoot {
  transform: scale(1.2);
  padding: 5px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  border-top: 1px solid #e0e0e0;
}

.leftActions {
  display: flex;
  align-items: center;
}

.rightActions {
  display: flex;
  align-items: center;
}

.deleteIcon {
  color: #999999;
  height: 16px;
  width: 16px;

  &:hover {
    color: #212b83;
  }
}

.iconPadding {
  padding: 4px;
}

.downloadIcon {
  color: #999999;
  height: 16px;
  width: 16px;

  &:hover {
    color: #212b83;
  }
}

.favIcon {
  height: 16px;
  width: 16px;
}

.reportText {
  font-size: 14px;
  padding: 4px;
  color: #000000;
}

.checkedContainer {
  align-items: center;
  cursor: pointer;
  background-color: #212b83;
  width: 50px;
  border-radius: 5px;
  height: 40px;
  justify-content: center;
  display: flex;
  svg {
    circle {
      fill: #212b83;
    }
  }
}

.caption {
  color: colors.$black;
  font-size: 14px;
  margin-bottom: 8px;
}

.editCaption {
  cursor: pointer;
  margin-top: 4px;
  font-size: 14px;
}

.cardInput {
  flex: 1 0 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.checked {
  background-color: #212b83;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.addCaption {
  font-size: 14px;
}
