@use 'src/styles/variables/colors';

.iconHeader {
  height: 18px;
  width: 16px;
}

.insideHeader {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e9eaf3;
}

.title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 36px;
  padding: 36px 16px 0 16px;

  svg {
    width: 32px;
    margin-right: 12px;
  }
}

.shareIcon {
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
  path {
    stroke: colors.$white;
  }
}

.noteWrapper {
  max-width: 500px;
  min-width: 300px;
  background: #ffffff;
  border: 1px solid #e9eaf3;
  box-sizing: border-box;
  border-radius: 14px;
  margin-left: 16px;
}

.creationBtnContainer {
  padding-right: 16px;
  padding-left: 16px;
  // max-width: 300px;
}

.paginationContainer {
  top: 0;
  text-align: right;
  padding: 12px 0;
  svg {
    width: 20px;
    cursor: pointer;
    height: 20px;
  }
  .arrowLeft {
    transform: rotate(180deg);
  }
  .paginationArrowContainer {
    position: relative;
    top: 3px;
  }
}

@media screen and (max-width: 576px) {
  .creationBtnContainer {
    width: 100%;
  }
}

@media screen and (min-width: 960px) {
  .scrollTopIcon {
    left: calc(50% - 32px);
  }
}
