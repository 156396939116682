@use 'src/styles/variables/colors';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  border: 1px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 12px;
}

.activeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px dashed #212b83;
}
.checkbox {
  position: relative;
  top: -11px;
}

.contentWrapper:not(:first-child):not(:last-child) {
  margin: 0 16px;
}

.contentWrapper {
  padding: 0;
}

.wrapperMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapperMainOpacity {
  opacity: 0.3;
}

.img {
  width: 21px;
  height: 18px;
  color: #666666;
  margin-right: 13px;
}

.textBlue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212b83;
  margin-left: 12px;
  cursor: pointer;
}

.textBlue:hover {
  text-decoration: underline;
  text-decoration-color: #212b83;
}

.greyText {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

input[type='file'] {
  display: none;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  // background-color: #212b83;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.dragDropArea {
  display: flex;
  align-items: center;
}
.fileIcon {
  position: relative;
  top: -2px;
  margin-right: 4px;
}

.downloadBtn {
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-left: 8px;
}

.downloadAllBtn {
  width: 25px;
  min-width: 25px;
  height: 25px;
  position: relative;
  left: 41px;
  top: 1px;
}

.editIcon,
.favIcon {
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-left: 8px;
}

.activeFavIcon {
  path {
    fill: colors.$primary;
  }
}

.smTableContainer {
  color: colors.$table-text;
  font-size: 14px;
  border-bottom: 1px solid colors.$border-color;
  padding-top: 16px;
  padding-bottom: 16px;
  & > div:first-child {
    cursor: pointer;
  }

  &:first-child {
    border-top: 1px solid colors.$border-color;
  }

  .ArrowDownIcon {
    width: 20px;
    height: 20px;
  }

  .ArrowDownIconRotate {
    transform: rotate(180deg);
  }

  .smTableDropdownContainer span {
    color: black;
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-right: 8px;
  }

  .smEditBtnContainer {
    cursor: pointer;
    color: colors.$primary;
    font-size: 16px;
    font-weight: bold;

    svg {
      path {
        stroke: colors.$primary;
      }
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .verticalLine {
    border-left: 1px solid colors.$border-color;
    height: 32px;
    position: relative;
    top: 8px;
  }

  .progress {
    width: 100%;
    height: 4px;
    background-color: colors.$gray;
    position: relative;

    .percentageCompletedProgress {
      position: absolute;
      height: 4px;
    }
  }
}

div.docViewer {
  background: white;
  iframe {
    // min-height: 700px;
  }
  & > div:last-child {
    min-height: 700px;
  }
}

.pdfViewer {
  width: 100%;
  // height: 700px;
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.fileName {
  width: 100%;
  display: flex;
  p {
    max-width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
