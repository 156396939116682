@use 'src/styles/variables/colors';

.table {
  margin-top: 16px;
  min-width: 650px;

  th, td {
    padding: 12px;
  }
}

.mobileContainer {
  display: block;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  padding: 12px;
}

.thumbnailContainer,
.mobileThumbnailContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.thumbnail {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 4px;
}

.fileName {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.captionText {
  font-size: 16px;
  color: #333;
}

.noCaptionText {
  font-style: italic;
  color: #999;
}

.actionsContainer {
  justify-content: center;
  display: flex;
  gap: 16px;
}

.detailRow {
  margin-top: 16px;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.expandButton {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.expandButton.expanded {
  transform: rotate(180deg);
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 4px;
}

