.darkSquare {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #000000;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;

  @media screen and (max-width: 768px) {
    border-radius: 50%;
    width: 28px;
    min-width: 28px;
    height: 28px;
    margin-right: 16px;
  }
}
