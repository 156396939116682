// semantic
$smallTextGrey: #666666;
$primary: #212b83;
$primary-label: #565ea1;
$gray: #f1f3f4;
$white: white;
$secondary: #808080;
$black: #000000;
$border-color: #e5e5e5;
$table-text: #666666;
$box-shadow: rgba(0, 0, 0, 0.12);

//map calor
$success: #009933;
$error: #cc0000;
$progress: #212b83;
