@use 'variables/fonts';

body {
  font-family: fonts.$primary-font;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
}

html,
body,
#root {
  height: 100%;
}

.MuiTypography-body1 {
  line-height: normal;
}

.Toastify__toast-container {
  width: auto;
}

.pac-container {
  z-index: 9999;
}

.MuiPickersBasePicker-pickerView {
  margin-top: 60px;
}

.MuiPickersBasePicker-container .MuiButton-root {
  min-height: auto;
}

.MuiPickersBasePicker-container .MuiPickersDatePickerRoot-toolbar {
  overflow: auto;
}

@media screen and (max-width: 576px) {
  .MuiPickersBasePicker-pickerView {
    margin-top: 100px;
  }
  .MuiPickersBasePicker-container .MuiPickersDatePickerRoot-toolbar {
    border-radius: 15px 15px 0 0;
  }

  .delete-dialog .MuiDialog-paper {
    width: auto;
    min-width: 350px;
  }
}
