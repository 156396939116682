@use 'src/styles/variables/colors';

.blockTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.heading {
  font-size: 12px;
  letter-spacing: 1.5px;
}

.workNumberExtension {
  max-width: 100px;
}

.separator {
  border: 1px solid colors.$border-color;
}

.helperText {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: colors.$smallTextGrey;
}

.rolesContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .optionsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    .optionsWrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

div.optionContainer {
  padding: 10px 40px 10px 20px;
  border: 1px solid colors.$primary;
  border-radius: 15px;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: colors.$primary;
}

.createRoleButton {
  border: 1px solid colors.$primary;
  border-radius: 15px;
  .plusIcon {
    width: 8px;
    height: 8px;
    margin-right: 8px;

    position: relative;
    bottom: -1px;
    path {
      stroke: colors.$primary;
    }
  }
}
