@use 'src/styles/variables/colors';
@use 'src/styles/variables/typo';

.sidebar {
  width: 230px;
  padding-top: 23px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  position: relative;

  &.collapsed {
    width: 64px;
  }

  .logotypeContainer {
    height: 42px;

    .logotype {
      width: 36px;
      position: relative;
      left: -6px;
      top: 1px;
    }

    .logoTitle {
      max-width: 120px;
      position: relative;
      top: 3px;
    }
  }

  .mainContainer {
    overflow-y: auto;
    .pagesContainer {
      // overflow-y: scroll;
    }
  }

  .logoutContainer {
    cursor: pointer;
    padding: 20px;
    color: #9095c1;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 16px;
    font-weight: 400;
    .iconContainer {
      padding: 10px;
      background-color: colors.$primary;
      border-radius: 100%;
      margin-right: 10px;

      svg {
        width: 15px;
        height: 16px;

        path {
          stroke: white;
        }
      }
    }

    &:hover {
      color: colors.$primary;
      .iconContainer {
        background-color: colors.$primary;
        path {
          stroke: white;
        }
      }
    }
    &,
    path {
      transition: color 0.2s, background-color 0.2s ease-out, fill 0.3s;
    }
  }

  & a {
    color: #9095c1;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  a.active {
    color: colors.$primary;
    background: white;
    &.hasNewNotification {
      color: rgb(255, 20, 20);
    }
    .iconContainer {
      background-color: colors.$primary;
      path {
        fill: white;
      }
    }

    &:visited,
    &:link,
    &:visited {
      &.hasNewNotification {
        color: rgb(255, 20, 20);
      }
      color: colors.$primary;
    }
  }

  & a:hover {
    .iconContainer {
      background-color: colors.$primary;
      path {
        fill: white;
      }
    }
  }

  & a,
  path {
    transition: color 0.2s, background-color 0.2s ease-out, fill 0.3s;
  }

  svg.settingIcon {
    width: 18px;
    height: 18px;
    path {
      stroke: colors.$primary;
    }
  }

  .userInfoContainer {
    color: #9095c1;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 16px;
    font-weight: 700;
    .iconContainer {
      padding: 10px;
      width: 32px;
      height: 32px;
      margin-right: 10px;
      border-radius: 10px;

      svg {
        width: 15px;
        height: 16px;
      }
    }
    &:hover {
      background-color: transparent;
      color: #9095c1;
    }
  }

  .iconContainer {
    padding: 10px;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.sidebarToggle {
  background: colors.$gray;
  transform: rotate(180deg);
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: -12px;
  z-index: 11;

  &.closed {
    transform: rotate(360deg);
  }
}

a.hasNewNotification {
  color: rgb(255, 20, 20);
}

a.hasNewNotification:hover {
  color: rgb(227, 0, 0);
}

.metricsTab {
  background: white;
  display: block;
  border: 1px solid #d3d5e6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px;
  .iconContainer {
    padding: 10px;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  .metricsTabTitle {
    font-weight: 700;
    color: colors.$primary;
  }

  .metricsStatistics {
    padding-left: 42px;
  }
}

@media screen and (max-width: 960px) {
  .sidebar {
    width: 64px;
  }
}

@media screen and (max-width: 576px) {
  .sidebar {
    display: none;
    position: absolute;
    background-color: colors.$gray;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    &.openSidebar {
      display: flex;
    }

    .logotypeContainer {
      display: none;
    }

    .logotype {
      width: 25px;
    }

    .logoTitle {
      max-width: 100px;
    }

    .iconContainer {
      width: 28px;
      height: 28px;
      margin-right: 16px;
    }

    & a {
      font-weight: 400;
      margin-bottom: 8px;
      font-size: 14px;
    }

    .logoutContainer {
      font-size: 14px;
      .iconContainer {
        margin-right: 16px;
      }
    }

    .closeSidebarIcon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: relative;
      left: 2px;
      top: 2px;
    }
  }

  .metricsTab {
    .iconContainer {
      width: 28px;
      height: 28px;
      margin-right: 16px;

      svg {
        width: 15px;
        height: 15px;
        min-width: 15px;
      }
    }
    .metricsTabTitle {
      font-weight: 400;
    }

    .metricsStatistics {
      padding-left: 44px;
      font-size: 12px;
    }
  }
}
