@use 'src/styles/variables/spacing';

@each $key, $padding in spacing.$paddings {
  .p-#{$key} {
    padding: #{$padding};
  }
  .px-#{$key} {
    padding-left: #{$padding};
    padding-right: #{$padding};
  }
  .py-#{$key} {
    padding-top: #{$padding};
    padding-bottom: #{$padding};
  }
  .pl-#{$key} {
    padding-left: #{$padding};
  }
  .pr-#{$key} {
    padding-right: #{$padding};
  }
  .pb-#{$key} {
    padding-bottom: #{$padding};
  }
  .pt-#{$key} {
    padding-top: #{$padding};
  }
}

@each $key, $padding in spacing.$paddings {
  .gap-#{$key} {
    gap: #{$padding};
  }
}

@each $key, $padding in spacing.$paddings {
  .row-gap-#{$key} {
    row-gap: #{$padding};
  }
}

@each $key, $margin in spacing.$margins {
  .m-#{$key} {
    margin: #{$margin};
  }
  .mx-#{$key} {
    margin-left: #{$margin};
    margin-right: #{$margin};
  }
  .my-#{$key} {
    margin-top: #{$margin};
    margin-bottom: #{$margin};
  }
  .ml-#{$key} {
    margin-left: #{$margin};
  }
  .mr-#{$key} {
    margin-right: #{$margin};
  }
  .mb-#{$key} {
    margin-bottom: #{$margin};
  }
  .mt-#{$key} {
    margin-top: #{$margin};
  }
}
@media screen and (min-width: 577px) {
  @each $key, $padding in spacing.$paddings {
    .p-sm-#{$key} {
      padding: #{$padding};
    }
    .px-sm-#{$key} {
      padding-left: #{$padding};
      padding-right: #{$padding};
    }
    .py-sm-#{$key} {
      padding-top: #{$padding};
      padding-bottom: #{$padding};
    }
    .pl-sm-#{$key} {
      padding-left: #{$padding};
    }
    .pr-sm-#{$key} {
      padding-right: #{$padding};
    }
    .pb-sm-#{$key} {
      padding-bottom: #{$padding};
    }
    .pt-sm-#{$key} {
      padding-top: #{$padding};
    }
  }
  @each $key, $margin in spacing.$margins {
    .m-sm-#{$key} {
      margin: #{$margin};
    }
    .mx-sm-#{$key} {
      margin-left: #{$margin};
      margin-right: #{$margin};
    }
    .my-sm-#{$key} {
      margin-top: #{$margin};
      margin-bottom: #{$margin};
    }
    .ml-sm-#{$key} {
      margin-left: #{$margin};
    }
    .mr-sm-#{$key} {
      margin-right: #{$margin};
    }
    .mb-sm-#{$key} {
      margin-bottom: #{$margin};
    }
    .mt-sm-#{$key} {
      margin-top: #{$margin};
    }
  }
}
@media screen and (min-width: 769px) {
  @each $key, $padding in spacing.$paddings {
    .p-md-#{$key} {
      padding: #{$padding};
    }
    .px-md-#{$key} {
      padding-left: #{$padding};
      padding-right: #{$padding};
    }
    .py-md-#{$key} {
      padding-top: #{$padding};
      padding-bottom: #{$padding};
    }
    .pl-md-#{$key} {
      padding-left: #{$padding};
    }
    .pr-md-#{$key} {
      padding-right: #{$padding};
    }
    .pb-md-#{$key} {
      padding-bottom: #{$padding};
    }
    .pt-md-#{$key} {
      padding-top: #{$padding};
    }
  }
  @each $key, $margin in spacing.$margins {
    .m-md-#{$key} {
      margin: #{$margin};
    }
    .mx-md-#{$key} {
      margin-left: #{$margin};
      margin-right: #{$margin};
    }
    .my-md-#{$key} {
      margin-top: #{$margin};
      margin-bottom: #{$margin};
    }
    .ml-md-#{$key} {
      margin-left: #{$margin};
    }
    .mr-md-#{$key} {
      margin-right: #{$margin};
    }
    .mb-md-#{$key} {
      margin-bottom: #{$margin};
    }
    .mt-md-#{$key} {
      margin-top: #{$margin};
    }
  }
}
@media screen and (min-width: 961px) {
  @each $key, $padding in spacing.$paddings {
    .p-lg-#{$key} {
      padding: #{$padding};
    }
    .px-lg-#{$key} {
      padding-left: #{$padding};
      padding-right: #{$padding};
    }
    .py-lg-#{$key} {
      padding-top: #{$padding};
      padding-bottom: #{$padding};
    }
    .pl-lg-#{$key} {
      padding-left: #{$padding};
    }
    .pr-lg-#{$key} {
      padding-right: #{$padding};
    }
    .pb-lg-#{$key} {
      padding-bottom: #{$padding};
    }
    .pt-lg-#{$key} {
      padding-top: #{$padding};
    }
  }
  @each $key, $margin in spacing.$margins {
    .m-lg-#{$key} {
      margin: #{$margin};
    }
    .mx-lg-#{$key} {
      margin-left: #{$margin};
      margin-right: #{$margin};
    }
    .my-lg-#{$key} {
      margin-top: #{$margin};
      margin-bottom: #{$margin};
    }
    .ml-lg-#{$key} {
      margin-left: #{$margin};
    }
    .mr-lg-#{$key} {
      margin-right: #{$margin};
    }
    .mb-lg-#{$key} {
      margin-bottom: #{$margin};
    }
    .mt-lg-#{$key} {
      margin-top: #{$margin};
    }
  }
}
@media screen and (min-width: 1441px) {
  @each $key, $padding in spacing.$paddings {
    .p-xl-#{$key} {
      padding: #{$padding};
    }
    .px-xl-#{$key} {
      padding-left: #{$padding};
      padding-right: #{$padding};
    }
    .py-xl-#{$key} {
      padding-top: #{$padding};
      padding-bottom: #{$padding};
    }
    .pl-xl-#{$key} {
      padding-left: #{$padding};
    }
    .pr-xl-#{$key} {
      padding-right: #{$padding};
    }
    .pb-xl-#{$key} {
      padding-bottom: #{$padding};
    }
    .pt-xl-#{$key} {
      padding-top: #{$padding};
    }
  }
  @each $key, $margin in spacing.$margins {
    .m-xl-#{$key} {
      margin: #{$margin};
    }
    .mx-xl-#{$key} {
      margin-left: #{$margin};
      margin-right: #{$margin};
    }
    .my-xl-#{$key} {
      margin-top: #{$margin};
      margin-bottom: #{$margin};
    }
    .ml-xl-#{$key} {
      margin-left: #{$margin};
    }
    .mr-xl-#{$key} {
      margin-right: #{$margin};
    }
    .mb-xl-#{$key} {
      margin-bottom: #{$margin};
    }
    .mt-xl-#{$key} {
      margin-top: #{$margin};
    }
  }
}
