@use 'src/styles/variables/colors';

.header {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 60px;
  letter-spacing: -0.5px;

  margin-bottom: 32px;

  @media (max-width: 768px) {
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 0.25px;
  }
}

.workNumberExtension {
  max-width: 100px;
}

.bottomText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
}

.bottomLink {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: colors.$primary;

  margin-left: 40px;
}
