@use 'src/styles/variables/colors';

.sortOption {
  path {
    fill: colors.$secondary;
  }
}

.activeSortOption {
  path {
    fill: colors.$black;
  }
}

.activeSortOptionRotate {
  transform: rotate(180deg);

  path {
    fill: black;
  }
}

.sortHeader {
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

.activeSortHeader {
  border: 1px solid #1164bd;
  background-color: rgba(0, 123, 255, 0.1);
}
