@use 'src/styles/variables/colors';

header {
  background-color: colors.$gray;

  h1 {
    height: 59px;
    position: relative;
  }
  .searchIcon {
    width: 18px;
    height: 18px;
  }

  .logotype {
    width: 25px;
  }

  .logoTitle {
    max-width: 100px;
  }
}

.backBtn {
  padding: 5px;
  width: 30px;
  transform: rotate(-90deg);
  position: relative;
  cursor: pointer;

  height: 30px;
  path {
    fill: black;
  }
}

@media screen and (max-width: 576px) {
  header {
    h1 {
      height: auto;
      position: relative;
    }

    .mbSearchField {
      display: none;
      width: 83.3333%;
      position: absolute;
      left: -24px;
      top: -8px;
      z-index: 99;
      padding-left: 16px;
      padding-right: 0;
      &.active {
        display: block;
      }
    }
  }

  .backBtn {
    padding: 5px;
    margin-left: -3px;
    margin-right: 5px;
    width: 25px;
    transform: rotate(-90deg);
    position: relative;
    cursor: pointer;
    top: 0px;
    height: 25px;
    min-width: 25px;
    path {
      fill: black;
    }
  }
}
