@use 'src/styles/variables/colors';

.starIcon {
  margin-right: 4px;
  path {
    fill: colors.$primary;
  }

  &.activeStarIcon {
    path {
      fill: colors.$white;
    }
  }
}