@use 'src/styles/variables/colors';
@import 'src/styles/atomic/mixins';

.pageLayout {
  padding-top: 12px;
  border-radius: 16px 16px 0px 0px;
  background-color: colors.$white;
  margin-right: 16px;
  flex: 1 1 auto;
  overflow: auto;
}

@include max-sm {
  .pageLayout {
    margin-right: 0px;
  }
}
