@use 'src/styles/variables/colors';

.wrapper {
  height: 200px;
  display: flex;
  flex-direction: column;
  border: 3px solid #e9eaf3;
  border-radius: 12px;
  flex: 1 1 200px;
  max-width: 200px;
  margin-bottom: 20px;
  margin-right: 8px;
  position: relative;
}

.fPart {
  width: 100%;
  height: 156px;
  border-radius: 12px 12px 0 0;
  cursor: pointer;
  img {
    width: 100%;
    border-radius: 12px 12px 0 0;
    height: 100%;
    object-fit: cover;
  }
}

.selectedItem {
  border: 3px solid colors.$primary;
}

.noPreview {
  width: 100%;
  border-radius: 12px 12px 0 0;
  height: 100%;
  background: #e9eaf3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(163, 163, 163);
}

.downloadBtn {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  // background: #ffffff;
  cursor: pointer;
}

.text {
  line-height: 24px;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imgBucket {
  width: 15px;
  height: 15px;
}

.btnClose {
  margin-left: 170px;
}

@media screen and (max-width: 768px) {
  .downloadBtn {
    width: 24px;
    height: 24px;
    right: 4px;
    top: 4px;
  }
}
