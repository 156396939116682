.container {
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.logoWrapper {
  display: none;
}

@media screen and (max-width: 768) {
  .logoWrapper {
    display: block;
  }
}
