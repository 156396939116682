@use 'src/styles/variables/colors';

.form-content {
  border-radius: 16px;
  box-shadow: 0 10px 18px rgba(77, 83, 87, 0.05);
}

.EyeIcon-icon-active {
  & path {
    fill: colors.$primary;
  }
}
