@use 'src/styles/variables/colors';

.sectionsListContainer {
  background-color: white;
  flex-basis: 320px;
  min-width: 320px;
  border-radius: 16px 16px 0px 0px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;

  .arrowRightIcon {
    transform: rotate(90deg);
    margin-left: 6px;
    position: relative;
    top: 2px;
    cursor: pointer;
    width: 16px;
    min-width: 16px;
  }

  .activeRightIcon {
    transform: rotate(-90deg);
    margin-left: 6px;
    cursor: pointer;
  }

  .sectionsList {
    padding: 14px 20px;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .visibilityStatus {
    width: 35px;
    text-align: center;
  }

  .uncheckedBox {
    width: 17px;
    min-width: 17px;
    height: 17px;
    display: inline-block;
    border: 1px solid black;
    border-radius: 50%;
  }

  .addSection {
    padding: 14px 20px;
    display: flex;
    align-items: center;
    color: colors.$primary;
    font-weight: 700;

    svg {
      width: 16px;
      min-width: 16px;
      margin-right: 4px;
      path {
        stroke: colors.$primary;
      }
    }
  }

  .addSubSection {
    // padding: 14px 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 14px;
    svg {
      width: 16px;
      min-width: 16px;
      margin-right: 4px;
    }
  }

  .editIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  .deleteIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-left: 8px;
  }
}

.invalidSection {
  color: red !important;
}

.collapseAll {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  overflow: visible;
  border: 1px solid colors.$border-color;
  border-radius: 7px;
  svg {
    height: 20px;
    width: 20px;
  }

  &.activeCollapseAll svg {
    transform: rotate(-180deg);
  }
}

.progressBar {
  background-color: #e9eaf3;
  width: 100%;
  height: 16px;
  position: relative;
  z-index: 100;

  .progress {
    z-index: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: colors.$primary;
  }
  p {
    z-index: 1;
    position: absolute;
    left: 50%;
    font-weight: 600;
    line-height: 16px;
  }
}

.hasIssue {
  path:first-child {
    fill: #cccccc;
  }
}

.hasIssueActive {
  path:first-child {
    fill: #ff9900;
  }
}

div.subsection {
  display: none;
}

div.activeSubsection {
  display: flex;
}

.editorContainer {
  width: 100%;
  margin-right: 16px;
  overflow-y: scroll;
  padding: 32px;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
}
.toggle,
.sectionToggle {
  display: none;
}

.uploadPhoto {
  border: 1px solid #212b83;
  padding: 16px 32px;
  font-size: 12px;
  min-width: 108px;
  box-sizing: border-box;
  min-height: 55px;
  transition: all 0.2s;
  font-family: News Gothic BT, sans-serif;
  font-weight: 700;
  line-height: 1.75;
  border-radius: 10px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  &:hover {
    background-color: rgb(23, 30, 91);
  }
}
.permit {
  display: flex;
  align-items: flex-start;
  & > * {
    flex: 1 1 auto;
    margin-right: 16px;
    margin-bottom: 0;
  }
  & > *:first-child {
    min-width: 250px;
  }
}

.dragDropArea {
  position: absolute;
  bottom: 16px;
  margin: 0 auto;
  right: 32px;
  width: 300px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  border: 2px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: white;
  z-index: 9999;
}
.clock {
  width: 22px;
  margin-left: 10px;
  path {
    stroke: colors.$primary;
  }
}
.activeSection {
  color: colors.$primary;
  font-weight: 600;
}

.viewToggler {
  margin-left: 8px;
  margin-right: 8px;
  align-items: center;
  cursor: pointer;
  &.activeViewToggler {
    font-weight: 600;
    color: colors.$primary;
  }
}

@media screen and (max-width: 576px) {
  .mobileHeader {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 30px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 0 1px rgba(0, 0, 0, 0.04);
    .reportName {
      margin-bottom: 6px;
    }
  }
  .sectionsListContainer {
    flex-basis: 100%;
    max-width: none;
    margin-right: 0;
    display: none;
  }

  .mobileSectionsListContainer {
    display: flex;
    position: absolute;
    z-index: 9;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .sectionsList {
    overflow-y: scroll;
  }

  .editorContainer {
    display: none;
    margin-right: 0px;
  }

  .mobileEditorContainer {
    display: block;
  }

  .toggle {
    display: block;
    position: absolute;
    top: 35px;
    right: 20px;
    svg {
      height: 30px;
      width: 30px;
      path {
        stroke: colors.$table-text !important;
      }
    }
  }

  .sectionToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    background-color: colors.$gray;
    width: calc(100% - 40px);
    bottom: 0;
    right: 20px;
    left: 20px;
    padding: 20px 0;
    border-radius: 16px 16px 0px 0px;
    p {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 13px;
      margin-right: 3px;
      color: colors.$primary;
    }
    svg {
      transform: rotate(90deg);
    }
  }
}
