.dayPicker {
  max-width: 80px;
}
.yearPicker {
  max-width: 130px;
  min-width: 110px;
}

.monthPicker {
  max-width: 170px;
  min-width: 150px;
}

@media screen and (max-width: 576px) {
  .dayPicker {
    max-width: 60px;
  }
  .yearPicker {
    max-width: 90px;
    min-width: 70px;
  }

  .monthPicker {
    max-width: 110px;
    min-width: 80px;
  }
}
