@use 'src/styles/variables/colors';

.searchSelectContainer {
  position: relative;
  width: fit-content;

  .selectDropdown {
    border: 1px solid colors.$border-color;
    border-radius: 13px;
    padding: 12px;
    cursor: pointer;
  }

  .dropdownArrowDownIcon {
    width: 16px;
    height: 28px;
    margin-left: 4px;
  }

  .activeSelectDropdown {
    color: colors.$primary;
    border-color: colors.$primary;
    svg {
      path {
        stroke: colors.$primary;
      }
      transform: rotate(180deg);
    }
  }

  .selectMenuContainer {
    position: absolute;
    margin-top: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 13px;
    top: 100%;
    z-index: 999;
    min-width: 100%;
    width: 300px;

    .react-select__input-container {
      border-bottom: 1px solid gray;
    }
  }
}

.dropdownSearchInput {
  min-width: 300px;
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    position: absolute;
    right: 0px;
  }
}

.searchIcon {
  width: 18px;
  path {
    stroke: colors.$primary;
  }
}
.closeIcon {
  width: 18px;
  cursor: pointer;
}
.react-select__indicator-separator {
  display: none;
}

div.react-select__indicator {
  display: none;
}

.react-select-container {
  div.react-select__control {
    border-radius: 13px 13px 0 0;
    outline: none;
    border-color: colors.$border-color;
    border-bottom: 0;
    box-shadow: none;
    z-index: 1;
  }
}

div.react-select__value-container {
  outline: none;
  padding: 32px 24px 7px 24px;
  border-radius: 13px 13p 0 0;
}

div.react-select__menu {
  padding: 7px 7px 7px 24px;
  margin-top: 0;
  box-shadow: none;
  border: 1px solid colors.$border-color;
  border-top: 0;
  border-radius: 0 0 13px 13px;
}

div.react-select__menu-list {
  padding-right: 7px;
  .react-select__option {
    color: #565d9f;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #e8e9f2;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: colors.$white;
  }
}

.react-select__option--is-selected {
  border-color: #212a83;
}

.react-select__option--is-selected:after {
  background-image: url('../../../assets/icons/check.svg');
  background-size: 10px 20px;
  display: inline-block;
  width: 10px;
  height: 20px;
  transform: scale(2);
  content: '';
}

div.react-select__group-heading {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #000000;
  padding-left: 0;
}

@media screen and (max-width: 576px) {
  .searchSelectContainer {
    .selectMenuContainer {
      left: 0;
    }
  }

  .dropdownSearchInput {
    min-width: 170px;
  }
}
