@use 'src/styles/variables/colors';

.editIcon {
  height: 26px;
  width: 26px;
}

.paginationContainer {
  top: 0;
  text-align: right;
  padding: 12px 0;
  svg {
    width: 20px;
    cursor: pointer;
    height: 20px;
  }
  .arrowLeft {
    transform: rotate(180deg);
  }
  .paginationArrowContainer {
    position: relative;
    top: 3px;
  }
}

.smColumnContainer {
  color: colors.$table-text;
  font-size: 14px;
  border-bottom: 1px solid colors.$border-color;
  padding-top: 16px;
  padding-bottom: 16px;
  & > div:first-child {
    cursor: pointer;
  }

  &:first-child {
    border-top: 1px solid colors.$border-color;
  }

  .ArrowDownIcon {
    width: 20px;
    height: 20px;
  }

  .ArrowDownIconRotate {
    transform: rotate(180deg);
  }

  .smColumnDropdownContainer span {
    color: black;
    font-size: 12px;
    letter-spacing: 1.5px;
    margin-right: 8px;
  }

  .smEditBtnContainer {
    cursor: pointer;
    color: colors.$primary;
    font-size: 16px;
    font-weight: bold;

    svg {
      path {
        stroke: colors.$primary;
      }
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.scrollTop {
  left: 50%;
}

@media screen and (min-width: 960px) {
  .scrollTop {
    left: calc(50% + 115px);
  }
}

@media screen and (max-width: 576px) {
  .documentIcon {
    path {
      fill: white;
    }
  }
}
