@use 'src/styles/variables/colors';

.table {
  margin-bottom: 40px !important;
  width: auto !important;
  table {
    width: auto !important;
  }
  th {
    background: colors.$border-color;
  }
}
