@use 'src/styles/variables/colors' as colors;

.column {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid colors.$border-color;
  color: colors.$table-text;
  font-size: 16px;
  vertical-align: top;
  &:not(:first-child) {
    padding-left: 16px;
    padding-right: 16px;
  }
  &:first-child {
    padding-left: 6px;
    padding-right: 6px;
  }
}

tr:hover {
  background-color: colors.$gray;
  cursor: pointer;
}

.wrapper {
  height: 26px;
  width: 26px;
}
