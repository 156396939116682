.mainContainer {
  padding: 32px 40px;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  color: #000;
  background-color: rgba(255, 255, 255, 0.9);
  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
}
