@use 'src/styles/variables/colors';

.heading {
  th {
    position: sticky;
    top: 0;
    z-index: 99;
    text-align: left;
    font-size: 10px;
    outline: none;
    text-transform: uppercase;
    border-bottom: 1px solid colors.$border-color;
    padding: 12px 6px;
    background-color: colors.$white;
    vertical-align: middle;
    &:not(:first-child) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
