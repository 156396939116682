.wrapper {
  color: #212b83;
  outline: none;
  padding: 10px 8px;
  position: relative;
  font-size: 16px;
  border-radius: 15px;
  letter-spacing: 0.5px;
  background-color: #ffffff;
  border: 1px solid #d3d5e6;
  box-sizing: border-box;
  width: 100%;
  height: 59px;
}

.wrapper:hover {
  border: 1px solid #212b83;
}

.wrapper:focus {
  border: 2px solid #212b83;
}

.label {
  position: absolute;
  top: -3px;
  left: -20px;
  padding: 0 6px;
  font-style: normal;
  font-weight: bold;
  color: #212b83;
  font-size: 16px;
  width: auto;
  font-weight: bold;
  letter-spacing: 0.1px;
  background-color: white;
  transform: translate(14px, -6px) scale(0.75);
}

.mainWrapper {
  position: relative;
  width: 100%;
}

.error > .wrapper {
  border-color: #cc0000;
}

.error:hover > .wrapper {
  border-color: #212b83;
}

.error:focus > .wrapper {
  border-color: #212b83;
}

.errorMessage {
  margin-left: 14px;
  margin-right: 14px;
}
