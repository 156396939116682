@use 'sass:meta';
@use 'src/styles/variables/colors';

@each $name, $content in module-variables(colors) {
  .text-#{$name} {
    color: $content;
  }

  .bg-#{$name} {
    background-color: $content;
  }
}
