.editIcon {
  position: absolute;
  right: -10px;
  top: -15px;
  z-index: 3;
  width: 30px;
  height: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
  cursor: pointer;
}

@media screen and (max-width: 568px) {
  .wrapper {
    min-height: 258px;
  }
}
