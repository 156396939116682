@use 'src/styles/variables/colors';

.activeViewToggleIcon {
  background: #e9eaf3;
  path {
    fill: colors.$primary;
  }
}

.viewToggleIcon {
  border: 1px solid colors.$border-color;
  padding: 4px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: 1px;
}
