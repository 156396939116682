@use 'src/styles/variables/colors';

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.w-50 {
  width: 50%;
}

.max-h-100 {
  max-height: 100%;
}

.h-100 {
  height: 100%;
}

.border-none {
  border: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

[class*='col-'] {
  padding-right: 16px;
  padding-left: 16px;
}

.p-0 {
  padding: 0;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.overflowed-text_2 {
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.direction {
  background-color: transparent;
  border: none;
  text-decoration: none;
  outline: none;
  font-weight: 700;
  color: colors.$primary;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.col-11 {
  flex: 0 0 91.666%;
  max-width: 91.666%;
}
.col-10 {
  flex: 0 0 83.333%;
  max-width: 83.333%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-8 {
  flex: 0 0 66.666%;
  max-width: 66.666%;
}
.col-7 {
  flex: 0 0 58.333%;
  max-width: 58.333%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-5 {
  flex: 0 0 41.666%;
  max-width: 41.666%;
}
.col-4 {
  flex: 0 0 33.333%;
  max-width: 33.333%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-2 {
  flex: 0 0 16.666%;
  max-width: 16.666%;
}
.col-1 {
  flex: 0 0 8.333%;
  max-width: 8.333%;
}

.layout-content-wrapper {
  padding: 0px 30px 16px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
  position: relative;
}

.base-layout-wrapper {
  padding-top: 0px;
  border-radius: 16px 16px 0px 0px;
  background-color: white;
  margin-right: 0px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.sortingCountContainer {
  display: inline-flex;
  width: 8px;
  height: 8px;
  background-color: #be2127;
  border-radius: 50%;
  position: relative;
  top: -5px;
  left: 2px;
}

.c-pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

@media screen and (min-width: 577px) {
  .base-layout-wrapper {
    margin-right: 16px;
  }

  .base-layout-wrapper > *:first-child {
    padding-top: 12px;
  }

  .d-sm-block {
    display: block;
  }

  .d-sm-flex {
    display: flex;
  }

  .d-sm-none {
    display: none;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm-11 {
    flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-sm-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-8 {
    flex: 0 0 66.666%;
    max-width: 66.666%;
  }
  .col-sm-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-5 {
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-sm-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-2 {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-sm-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
}

@media screen and (min-width: 769px) {
  .d-md-none {
    display: none;
  }
  .d-md-block {
    display: block;
  }
  .d-md-flex {
    display: flex;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-11 {
    flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-md-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-8 {
    flex: 0 0 66.666%;
    max-width: 66.666%;
  }
  .col-md-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-5 {
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-md-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-2 {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-md-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
}

@media screen and (min-width: 961px) {
  .d-lg-block {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-flex {
    display: flex;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-lg-11 {
    flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-lg-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-8 {
    flex: 0 0 66.666%;
    max-width: 66.666%;
  }
  .col-lg-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-5 {
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-lg-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-2 {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-lg-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
}

@media screen and (min-width: 1201px) {
  .d-xlg-none {
    display: none;
  }
  .d-xlg-block {
    display: block;
  }
  .d-xlg-flex {
    display: flex;
  }
  .col-xlg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xlg-11 {
    flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-xlg-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-xlg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xlg-8 {
    flex: 0 0 66.666%;
    max-width: 66.666%;
  }
  .col-xlg-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-xlg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xlg-5 {
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-xlg-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-xlg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xlg-2 {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-xlg-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
}

@media screen and (min-width: 1441px) {
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-11 {
    flex: 0 0 91.666%;
    max-width: 91.666%;
  }
  .col-xl-10 {
    flex: 0 0 83.333%;
    max-width: 83.333%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-8 {
    flex: 0 0 66.666%;
    max-width: 66.666%;
  }
  .col-xl-7 {
    flex: 0 0 58.333%;
    max-width: 58.333%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-5 {
    flex: 0 0 41.666%;
    max-width: 41.666%;
  }
  .col-xl-4 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-2 {
    flex: 0 0 16.666%;
    max-width: 16.666%;
  }
  .col-xl-1 {
    flex: 0 0 8.333%;
    max-width: 8.333%;
  }
}
