.container {
  overflow: visible;
  border-radius: 12px;
  position: relative;
  margin: 20px 0;
  height: calc(100% - 200px);
}

.mainSwiper {
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
}

.mainImage {
  width: 100%;
  height: 100%;
  max-height: 532px;
  object-fit: contain;
  border-radius: 5px;
}

.thumb {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 1, 20, 0.6);
  padding: 17px 0;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    height: 70px;
    padding: 8px 0;
  }
}

.thumb .swiper-wrapper {
  justify-content: center;
}

.thumbImage {
  width: 120px;
  height: 74px;
  object-fit: cover;
  border-radius: 8px;
  transition: opacity 0.3s ease, border-color 0.3s ease;
  opacity: 0.6;

    @media screen and (max-width: 768px) {
        width: 70px;
        height: 54px;
    }
}

.thumbImage:hover {
  opacity: 1;
}

.activeThumb {
  opacity: 1;
  border: 2px solid #007aff;
}

.captionWrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.editCaption {
  cursor: pointer;
  margin-top: 4px;
  font-size: 14px;
}

.useForReport {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reportText {
  font-size: 14px;
  color: #333;
}

.checkedContainer {
  align-items: center;
  cursor: pointer;
  background-color: #212b83;
  width: 50px;
  border-radius: 5px;
  height: 40px;
  justify-content: center;
  display: flex;
  svg {
    circle {
      fill: #212b83;
    }
  }
}

.checked {
  background-color: #212b83;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 48px;
  height: 48px;
  background-color: rgb(255, 255, 255, 0.8);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: rgb(255, 255, 255);
  }
}

.prevButton {
  left: -120px;
}

.nextButton {
  right: -120px;
}

.iconWhite{
  fill: rgba(0, 1, 20, 0.97);
}

.customSwiper {
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.customSlide {
  width: auto;
}

.topActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  font-size: 24px;
  font-weight: bold;
}

.iconText {
  font-size: 14px;
  color: #1A1A1A;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.deleteIcon {
  color: #BF2228;
  height: 20px;
  width: 20px;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  background-color: #f0f0f0;
  font-size: 16px;
  color: #888;
  border-radius: 8px;
}

.closeButton {
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    position: relative;
  }
}

.headerActions {
  display: flex;
  align-items: center;
}


