@use 'src/styles/variables/colors';

.icon {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  width: 48px;
  height: 48px;
  left: calc(50% - 24px);
  z-index: 1000000;
}

@media screen and (max-width: 568px) {
  .pageWithMap {
    bottom: 50px;
  }
}
