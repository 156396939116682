@use 'sass:map';
@use 'src/styles/variables/colors';
@use 'src/styles/variables/spacing';

.sidebarLink {
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;

  svg {
    flex-shrink: 0;
  }

  &.svgOffset svg {
    transition: 0.5s;
  }
}

.sidebarLink:hover {
  color: colors.$primary;
  background: white;
}
