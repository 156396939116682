.arrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.arrowLeft {
  transform: rotate(180deg);
}

.disabled {
  opacity: 0.4;
  cursor: default !important;
  pointer-events: none;
}
