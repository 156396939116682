.ArrowDownIcon {
  width: 25px;
  height: 25px;
  top: 11px;
}

.deleteIcon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  margin-left: 5px;
}

.error.autocomplete.fullWidth {
  fieldset {
    border-color: #cc0000;
  }
}

.errorMessage {
  margin-left: 14px;
  margin-right: 14px;
}
