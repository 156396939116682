@use 'src/styles/variables/colors';

.heading {
  font-size: 12px;
  letter-spacing: 1.5px;
}

.sectionsSeparator {
  border: 1px solid colors.$border-color;
  margin: 0;
}

.dropdownWrapper {
  min-width: 260px;
}
