@use 'src/styles/variables/breakpoints';

@mixin min-sm {
  @media (min-width: #{breakpoints.$sm + 1}px) {
    @content;
  }
}

@mixin min-md {
  @media (min-width: #{breakpoints.$md + 1}px) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{breakpoints.$lg + 1}px) {
    @content;
  }
}

@mixin min-xl {
  @media (min-width: #{breakpoints.$xl + 1}px) {
    @content;
  }
}

//max
@mixin max-sm {
  @media (max-width: #{breakpoints.$sm}px) {
    @content;
  }
}

@mixin max-md {
  @media (max-width: #{breakpoints.$md}px) {
    @content;
  }
}

@mixin max-lg {
  @media (max-width: #{breakpoints.$lg}px) {
    @content;
  }
}

@mixin max-xl {
  @media (max-width: #{breakpoints.$xl}px) {
    @content;
  }
}
