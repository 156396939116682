@use 'src/styles/variables/colors';

.borderBottom {
  border-bottom: 1px solid colors.$border-color;
}

.wrapper {
  content-visibility: auto;
}

.tableWrap {
  padding-left: 0 !important;
}

.paginationWrap {
  padding-right: 0 !important;
}

.removeFilterIcon {
  path {
    stroke: white;
  }
}

.searchField {
  max-width: 500px;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f1f3f4;
  z-index: 999;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-left: -8px;
  margin-right: -8px;
  .column {
    flex: 1 1 20%;
    max-width: 20%;
    display: flex;
    flex-direction: column;

    h2 {
      padding-left: 24px;
      padding-right: 24px;
      letter-spacing: 0.15px;
      white-space: nowrap;
    }

    .content {
      flex-grow: 1;
      border-radius: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 8px;
      background-color: colors.$gray;
      margin-right: 8px;
      margin-left: 8px;
      border: 2px solid white;

      &.draggedOver {
        border: 2px dashed #212b83;
        background-color: #212b8310;
        & > div {
          display: none;
        }
      }

      .workOrderItem {
        background-color: white;
        padding: 12px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        border: 0.5px solid #e9eaf3;
      }
      position: relative;
    }
  }
}
.archiveIcon {
  margin-right: 8px;
  position: relative;
  top: -2px;
  path {
    fill: colors.$primary;
  }
}

.dropdownButtonContainer {
  button {
    font-size: 14px;
    padding: 12px;
    font-weight: bold;
    min-height: auto;
    border: none;
  }
}

.smEditBtnContainer {
  cursor: pointer;
  color: colors.$primary;
  font-size: 14px;
  font-weight: bold;

  svg {
    path {
      stroke: colors.$primary;
    }
    width: 20px;
    height: 20px;
  }
}

.verticalLine {
  border-left: 1px solid colors.$border-color;
  height: 32px;
  position: relative;
  top: 8px;
}

.paginationContainer {
  top: 0;
  text-align: right;
  padding: 12px 0;
  svg {
    width: 20px;
    cursor: pointer;
    height: 20px;
  }
  .arrowLeft {
    transform: rotate(180deg);
  }
  .paginationArrowContainer {
    position: relative;
    top: 3px;
  }
}

.editIcon {
  width: 24px;
  cursor: pointer;
}

.greenArrowBlock {
  display: flex;
  color: #009933;
  align-items: center;
}

.smallArrow {
  path: {
    stroke: #009933;
  }
  height: 24px;
}

.modalWrapper {
  position: absolute;
  background-color: #fff;
  width: 128px;
  right: 0;
  padding: 8px 0;
  margin-top: 40px;
  z-index: 1;
  text-align: center;
}

.scrollTop {
  left: calc(50% - 30px);
}

@media screen and (max-width: 1200px) {
  .contentContainer {
    .column {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .contentContainer {
    display: none;
    .column {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  .scrollTop {
    left: calc(50% - 24px - 8px);
  }
}

@media screen and (max-width: 568px) {
  .creationBtnContainer {
    width: 100%;
  }
  .scrollTop {
    left: calc(50% - 24px);
  }
  .removeFilterIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    cursor: pointer;
    path {
      stroke: colors.$primary;
    }
  }
}
