.circularProgressBar {
  svg {
    overflow: visible;

    circle {
      stroke-width: 8;
    }
  }
}

.text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
