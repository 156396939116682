@use 'variables/colors.scss';
@import 'src/styles/atomic/mixins';

.sortOption {
  path {
    fill: colors.$secondary;
  }
}

.activeSortOption {
  path {
    fill: colors.$black;
  }
}

.activeSortOptionRotate {
  transform: rotate(180deg);

  path {
    fill: colors.$black;
  }
}

.creationBtnContainer {
  display: flex;
  justify-content: flex-end;
}

.tableItemEndAlign {
  width: 100%;
  justify-content: flex-end;
}

.redCellWithCross {
  color: red;

  &:after {
    content: '✖';
  }
}

@include max-sm {
  .creationBtnContainer {
    width: 100%;
  }
}

.tableHeaderRightAlign {
  text-align: right;
}
