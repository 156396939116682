@use 'src/styles/variables/colors';

.searchField {
  max-width: 500px;
  & > div {
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.4);
  }
}

.searchIcon {
  width: 18px;
  path {
    stroke: colors.$primary;
  }
}

.closeIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .searchField {
    & > div {
      background: white;
    }
  }
}
