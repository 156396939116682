@use 'src/styles/variables/colors';

.projectIcon {
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.tabWrapper {
  display: flex;
  position: relative;
}

.notification {
  width: 8px;
  height: 8px;
  background: colors.$error;
  border-radius: 45px;
  position: absolute;
  right: 30px;
  top: 23px;
}

.chartContainer {
  .chartWrapper {
    width: 100px;
    height: 100px;
    position: relative;

    .percents {
      position: absolute;
      top: 40%;
      right: 30%;
    }
  }
}
.reminderDate {
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
}

.tab {
  padding: 16px;
  min-width: 140px;
  cursor: pointer;
  border-bottom: 4px solid #d3d5e6;
  text-transform: capitalize;

  &.active {
    border-color: colors.$primary;
  }
}

.tableColumnHeading {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;

  color: colors.$primary;
}

.optionContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid colors.$border-color;
}

div.actionsContainer {
  position: relative;
  padding: 0 16px;
  .editIcon {
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: colors.$primary;
      }
    }
  }
  .naButton {
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #cccccc;
      }
    }
  }
  .naButton.naButtonActive {
    svg {
      path {
        fill: colors.$primary;
      }
    }
  }
}

.iconStyle {
  svg {
    path {
      fill: colors.$primary;
    }
  }
}

.ArrowDownIcon {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;

  &.activeArrowDownIcon {
    transform: rotate(180deg);
  }
}

.fileContainer {
  display: none;
}

.activeCategory {
  display: flex;
}

.arrowBlue {
  path {
    stroke: colors.$primary;
  }
}

.arrowRed {
  path {
    stroke: colors.$error;
  }
}

.textGreen {
  color: colors.$success;
}

.textRed {
  color: colors.$error;
}

.textBlue {
  color: colors.$primary;
}

.textBlack {
  color: colors.$black !important;
}

.wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

.noteCreationButton {
  width: 200px;
}

@media screen and (max-width: 576px) {
  .documentIcon {
    path {
      fill: white;
    }
  }

  .modalBottomBtns {
    width: 100%;
    justify-content: space-between;
    gap: 10px;
    button {
      font-size: 10px;
      width: 50% !important;
      padding: 5px;
    }
  }
}
