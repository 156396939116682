@use 'src/styles/variables/colors';

.title {
  font-weight: 600;
  font-size: 18px;
}

.servicesField {
  // height: 45px;
  // > div {
  //   height: 45px;
  // }
}

.serviceRow {
  padding: 6px 0px;
  border: 1px solid colors.$border-color;
  border-bottom: none;
  &:nth-last-child(1) {
    border-bottom: 1px solid colors.$border-color;
  }
}
