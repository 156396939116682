@use 'src/styles/variables/colors';

.deleteIcon {
  width: 18xp;
  max-width: 18px;
  height: 18px;
  cursor: pointer;
  path {
    stroke: #bf2228;
  }
}
.deleteTitle {
  color: #bf2228;
}

.imageName {
  word-break: break-all;
}

.imageBlock {
  max-height: 450px;
  object-fit: contain;
}

.loading {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: white;
  z-index: 999;
  width: 100%;
  max-width: 576px;
  min-width: 576px;
}

.fileThumb {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  max-height: 80px;
  height: 80px;
  min-height: 80px;
  border-radius: 5px;
  object-fit: cover;
}
.dialogEditIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: relative;
  margin-left: 8px;
  // top: 4px;
  // margin-top: 5px;
}

.closeIcon {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.textarea {
  textarea {
    font-size: 14px;
  }
}

.emptyState {
  // position: absolute;
  // top: -70px;
  // left: 0;
  // bottom: 0;
  // right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    max-width: 120px;
  }
}
.closePhotoDrawerIcon {
  position: absolute;
  right: 10px;
  top: 18px;
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
}

.arrowUp,
.arrowDown {
  position: absolute;
  left: 19px;
  max-width: 26px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  path {
    stroke: colors.$primary;
  }
}

.arrowUp {
  transform: rotate(180deg);
  top: 16px;
  left: 20px;
}

.arrowCategoryUp {
  transform: rotate(180deg);
  top: 4px;
}

.arrowCategorySecondUp {
  transform: rotate(180deg);
  top: 0px;
}

.arrowDown {
  top: 62px;
}

.arrowCategoryDown {
  bottom: -4px;
}
.arrowCategorySecondDown {
  bottom: 2px;
}
.mobileActive {
  background-color: #e9eaf3;
}
.imageContainer {
  display: flex;
  .checkbox {
    // margin-left: 4px;
    position: relative;
    top: -10px;
  }

  img {
    margin-left: 0px;
  }
}
.textField.subLabel {
  position: absolute;

  top: calc(100% + 6px);
  display: flex;
  align-items: flex-end;
  left: 0;
  right: 0;
  justify-content: center;
  // z-index: 999;
  width: 96%;
  z-index: 99;
  margin: 0 auto;
  & > div {
    padding: 7px;
    margin-top: -2px;
    margin-left: 5px;
    max-width: none;
    width: 100%;
    z-index: 99;
  }
}

.labelEditIcon {
  max-width: 20px;
  height: 20px;
  width: 20px;
  margin-left: 8px;
  // position: absolute;
  // top: 30%;
  // bottom: 0;
  // right: 15px;
  cursor: pointer;
  position: relative;
  top: 4px;
}

.checkedContainer {
  background-color: #212b83;
  min-width: 35px;
  border-radius: 5px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    circle {
      fill: #212b83;
    }
  }
}

.dragDropArea {
  position: absolute;
  bottom: 16px;
  margin: 0 auto;
  right: 32px;
  width: 300px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  border: 2px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: white;
  z-index: 9999;
}

.donwloadContainer {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}
.downloadIcon {
  width: 24px;
  margin-right: 8px;
  path {
    stroke: colors.$primary;
  }
}

.selectAllContainer {
  border-bottom: 2px solid #d3d5e6;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  padding-left: 27px;
}

.dragPointer {
  display: inline-block;
  height: 7px;
  width: 18px;
  border-bottom: 2px solid #d3d5e6;
  border-top: 2px solid #d3d5e6;
  margin: 0 auto;
  margin-top: 15px;
}

.mobileImageContainer {
  .arrowCategoryUp,
  .arrowCategorySecondUp,
  .arrowCategoryDown,
  .arrowCategorySecondDown {
    max-width: 20px;
    width: 20px;
    height: 20px;
    left: -15px;
  }

  .arrowCategoryUp {
    transform: rotate(180deg);
    top: 10px;
  }

  .arrowCategorySecondUp {
    transform: rotate(180deg);
    top: 16px;
  }

  .arrowCategoryDown {
    bottom: 12px;
  }
  .arrowCategorySecondDown {
    bottom: 18px;
  }
}

@media screen and (max-width: 576px) {
  .imageContainer {
    img {
      margin-left: 6px;
    }
  }
  .loading {
    min-width: 100%;
  }
}
