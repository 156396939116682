@use 'src/styles/variables/colors';
@import 'src/styles/atomic/mixins';

.section {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid colors.$table-text;
  box-shadow: -1px 2px 33px -18px colors.$box-shadow;

  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    width: 100%;

    span {
      display: flex;
      justify-content: center;
      width: 6px;
      text-align: center;
    }
  }

  .sectionArrow {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }

  &.expandedSection .sectionArrow {
    transform: rotate(-180deg);
  }
}

.actionsContainer {
  position: absolute;
  top: -70px;
  p {
    padding: 6px 5px;
    border: 2px solid colors.$table-text;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.pagination {
  position: absolute;
  padding: 0 !important;
  top: 24px;
  right: 20px;
}

@include max-sm {
  .actionsContainer {
    display: flex;
    flex-direction: column;
    top: -80px;
  }
}
