@import 'src/styles/atomic/mixins';

.exportBtn {
  position: absolute;
  left: 24px;
  top: -6px;
}

@include max-md {
  .exportBtn {
    position: initial;
  }
}
