@use 'src/styles/variables/colors';

.borderBottom {
  border-bottom: 1px solid colors.$border-color;
}

.archiveIcon {
  margin-right: 8px;
  position: relative;
  top: 0px;
  path {
    fill: colors.$primary;
  }
}

.closeIcon {
  path {
    stroke: white;
  }
}

.removeFilterIcon {
  path {
    stroke: colors.$white;
  }
}

@media screen and (max-width: 568px) {
  .removeFilterIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    cursor: pointer;
    path {
      stroke: colors.$primary;
    }
  }
}
