@use 'src/styles/variables/colors';

.asterisk {
  color: colors.$error;
  font-size: 28px;
}

.editorContainer {
  width: 100%;
  height: calc(100% - 62px);
  margin-right: 16px;
  overflow-y: hidden;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  flex-direction: column;
}

.breadcrumbsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  padding: 18px;
  height: 52px;
  gap: 40px;
  overflow-y: hidden;
  background-color: white;
  border-radius: 16px;
  margin-bottom: 10px;
}

.projectName,
.reportName {
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
}

.rightBtnArrow {
  transform: rotate(180deg);
}

.collapsedArrow {
  transform: rotate(90deg);
  position: absolute;
  top: 72px;
  left: 270px;
}

.expandedArrow {
  transform: rotate(-90deg);
}

.mobBreadcrumbsContainer {
  display: none;
}

.toggle,
.sectionToggle {
  display: none;
}

.uploadPhoto {
  border: 1px solid #212b83;
  padding: 16px 32px;
  font-size: 12px;
  box-sizing: border-box;
  min-height: 55px;
  transition: all 0.2s;
  font-family: News Gothic BT, sans-serif;
  font-weight: 700;
  line-height: 1.75;
  border-radius: 10px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  &:hover {
    background-color: rgb(23, 30, 91);
  }
}
.permit {
  display: flex;
  align-items: flex-start;
  & > * {
    flex: 1 1 auto;
    margin-right: 16px;
    margin-bottom: 0;
  }
  & > *:first-child {
    min-width: 200px;
  }
  & > *:nth-child(3) {
    min-width: 180px;
  }
  .deleteIcon {
    position: relative;
    top: 27px;
    cursor: pointer;
    left: 5px;
    max-width: 30px;
  }
}

.dragDropArea {
  position: absolute;
  bottom: 16px;
  margin: 0 auto;
  right: 32px;
  width: 300px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  border: 2px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: white;
  z-index: 9999;
}
.clock {
  width: 22px;
  margin-left: 10px;
  path {
    stroke: colors.$primary;
  }
}

.mobileSectionTitle {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 16px 16px 0px 0px;
  background: #ffffff;
  padding: 16px 24px;
  color: #212b83;
  position: sticky;
  top: 0;
  z-index: 4;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f3f4;
  z-index: 999;
}
.disabledIcon {
  path {
    fill: colors.$table-text !important;
  }
}
.prevSectionBtn {
  svg {
    path {
      fill: colors.$primary;
    }
  }
  &:hover {
    svg {
      path {
        fill: colors.$white !important;
      }
    }
  }
}

.lookBackIcon {
  position: absolute;
  right: 0;
  top: 2px;
  cursor: pointer;
  path {
    fill: colors.$primary;
  }
}

@media screen and (max-width: 576px) {
  .sectionBottomBtns {
    width: 100%;
    justify-content: space-between;
    button {
      font-size: 10px;
      width: 50% !important;
      padding: 5px;
    }
  }

  .btnsWrapper {
    width: 100%;
    flex-wrap: nowrap !important;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .breadcrumbsContainer {
    display: none;
  }

  .mobBreadcrumbsContainer {
    display: flex;
    margin-bottom: 15px;
  }

  .editorContainer {
    display: none;
    margin-right: 0px;
    padding: 0;
    height: 100%;
    padding-bottom: 100px;
    overflow: hidden !important;
  }

  .mobileEditorContainer {
    display: block;
  }

  .toggle {
    display: block;
    position: absolute;
    top: 11px;
    right: 16px;
  }

  .closeIcon {
    width: 24px;
    height: 24px;
  }

  .sectionToggle {
    display: block;
    position: absolute;
    transform: rotate(-90deg);
    background-color: #f1f3f4;
    padding: 5px 15px;
    left: -48px;
    top: 50%;
    font-weight: bold;
  }

  .arrowDownIcon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;
  }

  .permit {
    display: block;
    position: relative;
    .deleteIcon {
      position: absolute;
      padding: 6px;
      right: -18px;
      left: auto;
      top: 0px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      max-width: none;
      border: 1px solid black;
      border-radius: 50%;
      background-color: white;
    }
  }
  label.uploadPhoto {
    font-size: 12px;
    box-sizing: border-box;
    min-height: 55px;
    transition: all 0.2s;
    font-family: News Gothic BT, sans-serif;
    font-weight: 700;
    line-height: 1.75;
    border-radius: 10px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #212b83;
    border: 1px solid rgba(33, 43, 131, 0.5);
    background-color: transparent;
    margin-right: 16px;
    padding: 16px 15px;
    &:hover {
      color: white;
      border: 1px solid #212b83;
      background-color: rgb(23, 30, 91);
    }
  }

  .mobileSectionTitle {
    padding: 10px 16px;
  }
}

.sectionTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
