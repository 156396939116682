@use 'src/styles/variables/colors';

.textWrapper {
  color: #cc0000;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: 4px;
  svg {
    position: relative;
    margin-right: 4px;
  }
}
