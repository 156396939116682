.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.flex-1 {
  flex: 1;
}

.f-shrink-0 {
  flex-shrink: 0;
}

.align-start {
  align-items: flex-start;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-13 {
  order: 13;
}

@media screen and (min-width: 577px) {
  .justify-content-sm-end {
    justify-content: flex-end;
  }

  .flex-sm-row {
    flex-direction: row;
  }
}

@media screen and (min-width: 768px) {
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .order-md-13 {
    order: 13;
  }
  .order-md-14 {
    order: 14;
  }
  .order-md-15 {
    order: 15;
  }
  .order-md-16 {
    order: 16;
  }
  .order-md-17 {
    order: 17;
  }
  .order-md-18 {
    order: 18;
  }
  .order-md-19 {
    order: 19;
  }
  .order-md-20 {
    order: 20;
  }
  .order-md-21 {
    order: 21;
  }
  .order-md-22 {
    order: 22;
  }
  .order-md-23 {
    order: 23;
  }
  .order-md-24 {
    order: 24;
  }
  .order-md-25 {
    order: 25;
  }
  .order-md-26 {
    order: 26;
  }
}
