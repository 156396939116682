@use 'src/styles/variables/colors';
@import 'src/styles/atomic/mixins';

.point {
  display: inline-flex;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: colors.$primary;
}

.mapPopup {
  padding: 4px 8px;
  min-width: 300px;

  h2 {
    color: colors.$primary;
    font-size: 16px;
    font-weight: bold;
  }
  p {
    color: colors.$primary;
    font-size: 10px;
    border-bottom: 1px solid colors.$gray;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .dateContainer {
    font-size: 10px;
    letter-spacing: 1.5px;
  }

  svg {
    position: relative;
    top: 2px;
    path {
      stroke: colors.$primary;
    }
  }

  span {
    display: inline-block;
    width: 100%;
    color: colors.$primary;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background: none;
    border: none;
  }
}

.mapContent {
  padding: 0;
  min-height: 300px;
  min-width: 30%;
}

@include max-xl {
  .mapContent {
    min-width: 100%;
    height: 300px;
  }
}

@include max-sm {
  .mapContent {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: auto;
    top: calc(100% - 44px);
    z-index: 199;

    .mapHeader {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 44px;
      background-color: colors.$primary;
      box-shadow: 0px -1px 11px colors.$box-shadow;
      border-radius: 16px 16px 0px 0px;
      color: colors.$white;
      text-transform: uppercase;
      font-size: 12px;
    }
    &.openMobileMap {
      top: 0%;
      .mapHeader {
        border-radius: 0px;
      }
    }

    .arrowDownIcon {
      transform: rotate(180deg);
    }
  }
}
