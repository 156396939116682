.formHeadingContainer {
  display: flex;
  align-items: center;

  margin-bottom: 36px;
  margin-left: 16px;

  .formHeading {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
  }
  .plusIcon {
    height: 32px;
    width: 32px;
  }
}

.inputWrapper {
  max-width: 380px;
}

.sectionTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.checkboxesContainer {
  display: flex;
  flex-wrap: wrap;

  .instanceContainer {
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    .permissionsContainer {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}
