@use 'src/styles/variables/colors';

.title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 36px;
  margin-top: 36px;
  padding: 0 16px;

  svg {
    width: 32px;
    margin-right: 12px;
  }
}
