@use 'src/styles/variables/colors';
@import 'src/styles/atomic/mixins';

.removeFilterIcon {
  path {
    stroke: colors.$white;
  }
}

@include max-sm {
  .removeFilterIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    cursor: pointer;
    path {
      stroke: colors.$primary;
    }
  }
}
