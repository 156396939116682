.table {
  flex: 1;

  table {
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
  }
}

@media screen and (min-width: 1441px) {
  .scrollTopIcon {
    left: 65%;
  }
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paginationWrapper {
  margin-left: auto;
}
