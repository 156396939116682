@use 'src/styles/variables/colors';

.tabs {
  display: flex;
  border-bottom: 1px solid colors.$border-color;
}

@media screen and (max-width: 576px) {
  .tabs {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid colors.$border-color;
    a {
      display: flex;
      flex: 1 1 auto;
    }
  }
}
