@use 'src/styles/variables/colors';

.title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.4rem;
  svg {
    width: 32px;
    margin-right: 12px;
  }
}

.optionContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid colors.$border-color;
}

.tableColumnHeading {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: colors.$primary;
}
