@use 'src/styles/variables/colors';

.archiveIcon {
  margin-right: 8px;
  position: relative;
  top: -2px;
  path {
    fill: colors.$primary;
  }
}
