@use 'styles';

@font-face {
  font-family: 'News Gothic BT';
  src: url('./assets/fonts/newsgoth_bt/NewsGothicBT.ttf') format('ttf');
  font-display: swap;
}

.Toastify__toast {
  height: 64px;
  height: fit-content;
  width: fit-content;
  padding: 20px;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 53px;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.Toastify__toast-body {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0;
}
.Toastify__toast-icon {
  filter: invert(100%) sepia(10%) saturate(2%) hue-rotate(220deg) brightness(110%) contrast(101%);
  width: 45px;
  margin-right: 20px;
  @media screen and (max-width: '768px') {
    width: 20px;
  }
}
.Toastify__toast--error {
  background-color: #cc0000;

  .Toastify__toast-icon {
    content: url('assets/icons/attention.svg');
  }
}
.Toastify__toast--success {
  background-color: #009933;

  .Toastify__toast-icon {
    content: url('assets/icons/check.svg');
  }
}
.Toastify__toast--warning {
  background-color: #ff9900;

  .Toastify__toast-icon {
    content: url('assets/icons/caution.svg');
  }
}


@media screen and (max-width: 576px) {
  .Toastify__toast {
    font-size: 14px;
    padding: 10px 12px;
    height: auto;
    min-height: auto;
    width: 100%;
    margin-bottom: 12px;
    display: block;

    .Toastify__toast-body {
      white-space: pre-wrap;
    }
  }

  .Toastify__toast-container {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;
    &.Toastify__toast-container--bottom-right {
      top: 16px;
      bottom: auto;
    }
  }
}