@use 'src/styles/variables/colors';

.calendar {
  width: 22px;
  margin-left: 10px;
  path {
    stroke: colors.$primary;
  }
}

.errorMessage {
  margin-left: 14px;
  margin-right: 14px;
}

.error.autocomplete.fullWidth {
  fieldset {
    border-color: #cc0000;
  }
}

.datePicker {
  .MuiDialog-paperWidthSm {
    width: auto;
  }
}
