.wrapper {
  border: none;
  min-width: 0;
  min-height: 0;
  padding: 0;
  align-items: flex-start;
  justify-content: start;
}

.title {
  font-weight: 600;
}

.text {
  max-width: 400px;
}

.emptyContainer {
  svg {
    min-width: 48px;
  }
}

.wrapper:hover {
  background: none;
}
