@use 'src/styles/variables/colors';

.modalWrapper {
  position: absolute;
  background-color: colors.$white;
  width: 186px;
  right: 24px;
  margin-top: 35px;
  padding: 8px 0;
  z-index: 1;
  border: 2px solid colors.$secondary;
  border-radius: 16px;
}

.closeModalIcon {
  position: absolute;
  top: -14px;
  right: -14px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background: colors.$white;
  border: 2px solid colors.$secondary;
  border-radius: 50%;
}

.icon {
  height: 26px;
  width: 26px;
  margin-right: 8px;
}
