.sliderContainer {
  display: flex;
  flex-direction: column;
}

.formLabel {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 16px;
}

.radioButtonsContainer {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
}

@media screen and (max-width: 768) {
  .clock {
    overflow: scroll;
    height: 100%;
  }
}
