.listStyle {
  font-family: News Gothic BT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  margin-left: 32px;

  color: rgba(255, 255, 255, 0.8);
}

.rightBlock {
  border-radius: 32px 0px 0px 32px;
}

.header {
  opacity: 0;
}

.footer {
  display: none;
}

.logoGroupSize {
  height: 400px;
  width: 400px;
}

@media screen and (max-width: 1200px) {
  .leftBlock {
    width: 100%;
  }
  .displayNone {
    display: none;
  }
  .header {
    width: 100%;
    height: 100px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;

    background: #212b83;
    border-radius: 0px 0px 16px 16px;
  }
  .footer {
    height: 76px;
    min-height: 76px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    list-style: none;

    background: #212b83;
    border-radius: 16px 16px 0px 0px;
  }

  .listStyle {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .rightBlock {
    display: none !important;
  }
}
