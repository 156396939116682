@use 'src/styles/variables/colors';

.rowContainer {
  color: colors.$table-text;
  font-size: 14px;
  border-bottom: 1px solid colors.$border-color;
  padding-top: 16px;
  padding-bottom: 16px;
  & > div:first-child {
    cursor: pointer;
  }

  &:first-child {
    border-top: 1px solid colors.$border-color;
  }

  .arrowDownIcon {
    width: 20px;
    height: 20px;
  }

  .arrowDownIconRotate {
    transform: rotate(180deg);
  }

  .actionContainer {
    cursor: pointer;
    color: colors.$primary;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid colors.$border-color;
    padding: 5px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;

    &:last-child {
      border-right: none;
    }

    svg {
      path {
        stroke: colors.$primary;
      }
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}
