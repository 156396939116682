.tileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 153, 51, 0.08);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 4px 40px;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 12px;
 
}

.received {
  background: rgba(0, 153, 51, 0.04);
  color: #009933;
}
.rejected {
  background: rgba(113, 0, 153, 0.04);
  color: #710099;
}
.missing {
  background: rgba(86, 94, 161, 0.04);
  color: #565ea1;
}

.count {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
}

.text {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
