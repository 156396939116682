@use 'src/styles/variables/colors';

.tab {
  border-bottom: 4px solid colors.$border-color;
  white-space: nowrap;
  align-items: center;

  span {
    height: 22px;
    svg {
      margin-right: 8px;
      width: 22px;
      height: 22px;
      position: relative;
      top: -3px;
    }
  }
}

.active {
  color: colors.$primary;
  border-color: colors.$primary;

  svg path {
    stroke: colors.$primary;
  }
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: #565ea1;
  border-radius: 50%;
  color: white;
  margin-left: 10px;
  font-size: 12px;
}

@media screen and (max-width: 768px) {
  .tab {
    border-bottom: 3px solid colors.$border-color;
  }
  .active {
    color: colors.$primary;
    border-bottom: 3px solid colors.$primary;
  }
}
