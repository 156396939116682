.mainWrapper {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;

  display: flex;
  @media (max-width: 1200px) {
    height: auto;
    flex-direction: column;
  }
}

.imageContainer {
  height: 100%;
  border-radius: 0px 32px 32px 0px;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  background-image: url('../../assets/images/registration-page.png');
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1200px) {
    align-items: center;
    justify-content: center;
    min-height: 100px;
    border-radius: 0px 0px 16px 16px;
    background-position: center;
  }
}

.logo {
  z-index: 1;
  margin-top: 3rem;
  margin-left: 2rem;

  @media (max-width: 1200px) {
    margin: 0;
  }
}

.linksContainer {
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  justify-content: space-between;

  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.8);

  font-size: 16px;
  line-height: 24px;

  @media (min-width: 1200px) {
    margin-bottom: 3rem;
  }
}

.formContainer {
  @media (max-width: 1200px) {
    margin-top: 52px;
    margin-bottom: 76px;
  }
}

.bottomImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../assets/images/registration-page.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px 16px 0px 0px;

  height: 76px;
  @media (min-width: 1200px) {
    display: none;
  }
}
