.icon {
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
}

.text {
  max-width: 300px;
  font-weight: bold;
}
