@use 'src/styles/variables/colors';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  border: 1px dashed #cccccc;
  box-sizing: border-box;
  border-radius: 12px;
}

.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.activeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px dashed #212b83;
}

.wrapperMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.img {
  width: 21px;
  height: 18px;
  color: #666666;
  margin-right: 13px;
}

.textBlue {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #212b83;
  margin-left: 12px;
  cursor: pointer;
}

.textBlue:hover {
  text-decoration: underline;
  text-decoration-color: #212b83;
}

.greyText {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

input[type='file'] {
  display: none;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  list-style: none;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.dragDropArea {
  display: flex;
  align-items: center;
}

.cardWrapper {
  align-items: flex-start;
}
