@use 'src/styles/variables/colors';
@import 'src/styles/atomic/mixins';

.tab {
  display: flex;
  border-bottom: 1px solid colors.$border-color;
  margin-bottom: 32px;
  overflow-x: auto;
}

.tabItem {
  border-bottom: 4px solid colors.$border-color;
  white-space: nowrap;
  align-items: center;

  span {
    height: 22px;
    svg {
      margin-right: 8px;
      width: 22px;
      height: 22px;
      position: relative;
      top: -3px;
    }
  }
}

.active {
  color: colors.$primary;
  border-color: colors.$primary;

  svg path {
    stroke: colors.$primary;
  }
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: #565ea1;
  border-radius: 50%;
  color: colors.$white;
  margin-left: 10px;
  font-size: 12px;
}

@include max-md {
  .tabItem {
    border-bottom: 3px solid colors.$border-color;
  }
  .active {
    color: colors.$primary;
    border-bottom: 3px solid colors.$primary;
  }
}

@include max-sm {
  .tab {
    flex-wrap: wrap;
    border-bottom: 1px solid colors.$border-color;
    margin-bottom: 0;
    overflow-x: initial;
    a {
      display: flex;
      flex: 1 1 auto;
    }
  }
}
