.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

.text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
  padding-left: 44px;
}

@media screen and (max-width: 576px) {
  .text {
    padding-left: 0px;
  }
}
