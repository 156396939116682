@use 'src/styles/variables/colors';
@import 'src/styles/atomic/mixins';

.descriptionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9eaf3;
}

.descriptionToggler {
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  font-size: 12px;
  top: -31px;
  right: 16px;
  white-space: nowrap;
  background-color: colors.$gray;
  padding: 4px 8px;
}

.buildingIcon,
.coinIcon,
.userIcon {
  height: 18px;
  width: 16px;
  margin-right: 8px;
}

@include max-sm {
  .arrowUpIcon {
    transform: rotate(180deg);
    path {
      fill: colors.$black;
    }
  }

  .arrowDownIcon {
    transform: rotate(0deg);
  }

  .descriptionContainer {
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 100%;
    border: none;
    z-index: 98;
    background-color: colors.$gray;
    &.descriptionContainerOpen {
      bottom: 0;
      top: auto;
    }
  }
}
