.pointer {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.visibility-auto {
  content-visibility: auto;
}

.required:after {
  content: '\00a0 *';
  display: inline-block;
}

@media screen and (max-width: 576px) {
  .visibility-auto {
    content-visibility: initial;
  }
}
