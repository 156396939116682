div.dropdownTitleContainer {
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d5e6;
  margin-bottom: 16px;

  .titleText {
    white-space: nowrap;
  }
}

.datePickerContainer {
  align-items: center;
}

.datePicker {
  width: 200px;
}

@media screen and (max-width: 576px) {
  .datePickerContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  div.dropdownTitleContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}
