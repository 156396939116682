@use 'src/styles/variables/colors';

.linearProgressHorizontal {
  height: 4px;
  min-height: 4px;
  margin-right: 16px;
  margin-left: 4px;
  background-color: colors.$gray;
  position: relative;
  margin-top: 4px;
  .progress {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.linearProgressVertical {
  width: 4px;
  min-width: 4px;
  height: 97px;
  margin-left: 4px;
  background-color: colors.$gray;
  position: relative;
  .progress {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
