@use 'src/styles/variables/colors';

.cardWrapper {
  flex: 1 1 100%;
  max-width: 100%;
  background: #ffffff;
  border: 0.5px solid #e9eaf3;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  // height: 100%;
}

.header {
  border-bottom: 0.5px solid #d3d5e6;
}

.icon {
  height: 18px;
  width: 18px;
  min-width: 18px;
}

.errorMessage {
  color: #cc0000;
}

.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  margin-right: 16px;
  color: #000000;
  a {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.linearProgress {
  height: 4px;
  min-height: 4px;
  margin-right: 16px;
  margin-left: 4px;
  background-color: colors.$gray;
  position: relative;
  margin-top: 4px;
  .progress {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.wrapper {
  position: relative;
}

.countCircle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #ffffff;
  position: absolute;
  right: 10px;
  top: 5px;
  background: #bf2228;
  border-radius: 45px;
}

.toDo {
  color: #999999;
  path {
    fill: #999999;
  }
}

.readyToWrite {
  color: colors.$primary;
  circle {
    fill: white;
  }
  path {
    fill: colors.$primary;
  }
}

.submitted {
  color: #009933;
  circle {
    fill: white;
  }
  path {
    fill: #009933;
  }
}

.errorMessage {
  color: #bf2228;
}

.btnWrapper {
  border-top: 1px solid colors.$border-color;
  margin-bottom: 16px;
}
