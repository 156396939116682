@use 'src/styles/variables/colors';

.noteContainer {
  width: 100%;
  background: colors.$gray;
  border-radius: 16px;
  padding: 24px 32px;
}

.deleteIcon {
  height: 28px;
  width: 28px;
  min-width: 28px;
}

.projectInfo {
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: colors.$secondary;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.linkContainer {
  display: none;
}

.arrowLeftIcon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  path {
    stroke: colors.$primary;
  }
}

.noteTitle {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: #000000cc;
}

.editIcon {
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.noteText {
  color: colors.$smallTextGrey;
  strong {
    font-weight: bold;
  }
}

.noteInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.noteDateInfoWrapper {
  position: relative;
}

.noteDateInfoWrapper::before {
  content: '—';
  font-size: 12px;
  line-height: 16px;
  color: colors.$smallTextGrey;
  position: absolute;
  left: -20px;
}

.noteDateInfo {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: colors.$smallTextGrey;
}

@media screen and (max-width: 768px) {
  .linkContainer {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .noteContainer {
    padding: 16px 16px 32px;
  }

  .projectInfo {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.5px;
  }

  .noteText {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .noteInfoContainer {
    margin-top: 32px;
  }

  .noteDateInfoWrapper::before {
    top: -20px;
    left: 0;
  }
}
