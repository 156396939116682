@use 'src/styles/variables/colors';

.dropdownContainer {
  position: relative;
  width: fit-content;

  .dropdown {
    border: 1px solid colors.$border-color;
    border-radius: 13px;
    padding: 12px;
    cursor: pointer;
    width: 100%;
  }

  .ArrowDownIcon {
    width: 16px;
    height: 28px;
    margin-left: 4px;
  }

  .activeDropdown {
    color: colors.$primary;
    border-color: colors.$primary;
    svg {
      path {
        stroke: colors.$primary;
      }
      transform: rotate(180deg);
    }
  }

  .customDropdown {
    position: absolute;
    margin-top: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 13px;
    top: 100%;
    background: white;
    padding: 36px 24px;
    z-index: 999;
  }
}

@media screen and (max-width: 768px) {
  .dropdownContainer {
    .customDropdown {
      padding: 24px 20px;
    }
  }
}

@media screen and (max-width: 576px) {
  .customDropdown {
    max-width: 270px;
  }
}
