@use 'src/styles/variables/colors';

.mainContainer {
  min-width: 90px;
}

.button {
  height: 45px;
  width: 45px;
  svg {
    path {
      fill: #cccccc;
      stroke: #cccccc;
          fill: colors.$error;
    }
    circle {
      stroke: #cccccc;
    }
  }
}

.active {
  svg {
     path {
      stroke: colors.$success;
    }
    circle {
      stroke: colors.$success;
    }
  }
}

.rejected {
  svg {
    path {
      stroke: colors.$error;
    }
  }
}
