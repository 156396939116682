@use 'src/styles/variables/colors';

.noteOverviewContainer {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-radius: 14px;
  margin: 8px 0px;
  position: relative;
  // min-width: 300px;
  border: 1px solid colors.$border-color;
  @media screen and (max-width: 576px) {
    padding: 16px;
  }
}
.noteDescription {
  width: calc(100% - 40px);
  overflow: hidden;
}

.projectInfo {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #000000cc;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-top: 8px;

  color: #000000;

  @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;

    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.dateContainer {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.dateLabel {
  color: #808080;
}
.dateInfo {
  color: colors.$smallTextGrey;
}

.imgBucket {
  height: 16px;
  width: 16px;
}

.absoluteDiv {
  align-items: flex-end;
  margin-bottom: 20px;
}

.textContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.deleteButton {
  position: relative;
  top: -2px;
}
